import React from 'react';

const GrabHandLeft = props => {
  return (
    <svg
      {...props}
      css={`
        ${props.css}
        .cls-1-grab-arm-left {
          fill: #fff;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="155.648"
      height="90.56"
    >
      <defs />
      <g id="łapa" transform="translate(2.5 2.5)">
        <g id="Zasób_8" data-name="Zasób 8">
          <g id="Warstwa_1" data-name="Warstwa 1">
            <path
              id="Path_57"
              d="M0 0l.7 9.82 1.08.14L4.96.64z"
              className="cls-1-grab-arm-left"
              data-name="Path 57"
              transform="translate(105.04 75.57)"
            />
            <path
              id="Path_58"
              d="M0 .01l1.97 9.64h1.09L5 0z"
              className="cls-1-grab-arm-left"
              data-name="Path 58"
              transform="translate(122.37 77.15)"
            />
            <path
              id="Path_59"
              d="M0 .62l3.14 9.33 1.08-.14L4.96 0z"
              className="cls-1-grab-arm-left"
              data-name="Path 59"
              transform="translate(139.74 78.11)"
            />
            <path
              id="Path_60"
              d="M0 51.88h83.5A16.19 16.19 0 0 1 98.71 69a8.65 8.65 0 1 0 17.26 1 8.65 8.65 0 1 0 17.271 1 8.65 8.65 0 1 0 17.27 1A68.09 68.09 0 0 0 86.57 0H0"
              data-name="Path 60"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GrabHandLeft;
