import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const ContactLeft1 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.Left1}
      StalkComponent={Stalk.H220}
      leafStyles={`
        top: 19px;
        left: -2px;
        transform-origin: 83% 94%;
      `}
      hoverRotate="20deg"
    />
  );
};

export default ContactLeft1;
