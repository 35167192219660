import React from 'react';
import { graphql } from 'gatsby';

import withLayout from '../layout';

import SubHeader from '../components/SubHeader';
import { Gallery, CallToAction } from '../components/homePage';

const IndexPage = ({ data }) => (
  <>
    <SubHeader
      images={data.slider.edges}
      modalHeader="home.orient na orient"
      modalText="home.modalParagraph"
      buttonText="home.modalButton"
      linkTo="/onas"
    />
    <Gallery gallery={data.gallery.edges} />
    <CallToAction />
  </>
);

const customProps = {
  localeKey: 'home',
  keywords: ['orientujsie'],
};

export const query = graphql`
  query {
    slider: allFile(filter: { relativeDirectory: { eq: "home/slider" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920, srcSetBreakpoints: [1280, 1440], quality: 100) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "home/gallery" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`;

export default withLayout(customProps)(IndexPage);
