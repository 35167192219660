import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error11 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error11}
      StalkComponent={Stalk.H180}
      leafStyles={`
        top: 25px;
        left: -45px;
        transform-origin: 90% 93%;
      `}
      hoverRotate="25deg"
    />
  );
};

export default Error11;
