import React from 'react';
import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Left2 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.Left2}
      StalkComponent={Stalk.H230}
      leafStyles={`
        top: 20px;
        left: 90px;
        transform-origin: 15% 94%;
      `}
      hoverRotate="-10deg"
    />
  );
};

export default Left2;
