import React from 'react';

import { display } from 'styled-system';
import { Box } from '@rebass/grid';
import Flower from '../../Flowers';
import Waves from '../../../assets/waves';

const LeftFlowers = () => {
  return (
    <Box
      css={`
        ${display}
        position: relative;
        top: 168px;
        left: -300px;
      `}
      display={{ mobile: 'none', laptop: 'block' }}
    >
      <Flower.Left1
        css={`
          position: absolute;
          bottom: 55%;
          left: 80px;
        `}
      />
      <Flower.Left2
        css={`
          position: absolute;
          bottom: 81%;
          left: 130px;
        `}
      />
      <Waves.HomeGallery
        css={`
          position: absolute;
        `}
      />
    </Box>
  );
};

export default LeftFlowers;
