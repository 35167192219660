import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import { space } from 'styled-system';

import Button from '../Button';
import Link from '../Link';
import CatOnWaves from '../CatOnWaves';
import Flowers from '../Flowers/BottomWaves';

const CallToAction = () => {
  return (
    <Flex as="section" flexDirection="column" alignItems="center">
      <h3
        css={`
          ${space}
          max-width: 610px;
          line-height: 1.5;
        `}
        mt={{ mobile: 90, laptop: 140 }}
        mx={20}
        mb={70}
      >
        <FormattedMessage id="home.callToAction" />
      </h3>
      <Flex flexDirection={['column', 'row']}>
        <Button as={Link} secondary mr={[0, 20]} mb={[20, 0]} to="/menu">
          <FormattedMessage id="nav.seeMenu" />
        </Button>
      </Flex>
      <Box
        width={1920}
        css={`
          position: relative;
        `}
      >
        <Flowers />
        <CatOnWaves />
      </Box>
    </Flex>
  );
};

export default CallToAction;
