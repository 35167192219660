import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import { Flex, Box } from '@rebass/grid';
import { zIndex } from 'styled-system';

import ScratchArm from './ScratchArm';
import LeftFlowers from './LeftFlowers';
import RightFlowers from './RightFlowers';

import useStartOnPosition from '../../../helpers/hooks/useStartOnPosition';
import { sortImages } from '../../../helpers';

const Images = props => {
  const image2Ref = useRef();

  const shouldGrabArm = useStartOnPosition(image2Ref);

  const { gallery } = props;
  const galleryWithRef = sortImages(gallery).map((image, index) => ({
    ...image,
    ref: index === 2 ? image2Ref : null,
  }));

  return (
    <Flex
      {...props}
      css={`
        position: relative;
      `}
      flexWrap="wrap"
      width={1}
      justifyContent="center"
    >
      <LeftFlowers />
      {galleryWithRef.map((image, index) => (
        <Box
          key={index}
          css={`
            width: 100%;
            max-width: 400px;
            position: relative;
          `}
          my={10}
          mx={[0, 10]}
          ref={image.ref}
        >
          <Img css={zIndex} zIndex={index === 2 ? 3 : 1} fluid={image.node.childImageSharp.fluid} />
          {index === 2 && (
            <ScratchArm
              startGrab={shouldGrabArm}
              display={{ mobile: 'none', desktop: 'block' }}
              zIndex={2}
              left={60}
              isLeft
            />
          )}
        </Box>
      ))}
      <RightFlowers />
      <ScratchArm display={{ tablet: 'none' }} startGrab={shouldGrabArm} zIndex={3} />
    </Flex>
  );
};

Images.propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Images;
