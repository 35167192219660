import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error06 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error06}
      StalkComponent={Stalk.H178R}
      leafStyles={`
        top: 32px;
        left: 69px;
        transform-origin: 7% 92%;
      `}
      hoverRotate="-15deg"
    />
  );
};

export default Error06;
