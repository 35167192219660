import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error07 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error07}
      StalkComponent={Stalk.H180}
      leafStyles={`
        top: 28px;
        left: -44px;
        transform-origin: 94% 92%;
      `}
      hoverRotate="25deg"
    />
  );
};

export default Error07;
