import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error05 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error05}
      StalkComponent={Stalk.H178R}
      leafStyles={`
        top: 12px;
        left: 67px;
        transform-origin: 12% 97%;
      `}
      hoverRotate="20deg"
    />
  );
};

export default Error05;
