import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error01 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error01}
      StalkComponent={Stalk.H210}
      leafStyles={`
        top: 29px;
        left: -55px;
        transform-origin: 91% 94%;
      `}
    />
  );
};

export default Error01;
