import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error10 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error10}
      StalkComponent={Stalk.H270}
      leafStyles={`
        top: 10px;
        left: 72px;
        transform-origin: 43% 97%;
      `}
      hoverRotate="-15deg"
    />
  );
};

export default Error10;
