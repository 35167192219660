import React from 'react';
import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Left1 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.Left1}
      StalkComponent={Stalk.H230}
      leafStyles={`
        top: 20px;
        left: 2px;
        transform-origin: 87% 93%;
      `}
    />
  );
};

export default Left1;
