import React from 'react';
import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Left2 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.Right1}
      StalkComponent={Stalk.H205}
      leafStyles={`
        top: 20px;
        left: 72px;
        transform-origin: 20% 94%;
      `}
      hoverRotate="-20deg"
    />
  );
};

export default Left2;
