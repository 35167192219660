import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error12 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error12}
      StalkComponent={Stalk.H178R}
      leafStyles={`
        top: 19px;
        left: 69px;
        transform-origin: 15% 96%;
      `}
      hoverRotate="-15deg"
    />
  );
};

export default Error12;
