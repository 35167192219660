import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const Error01 = props => {
  return (
    <FlowerConstructor
      {...props}
      position="absolute"
      LeafComponent={Leaf.Error02}
      StalkComponent={Stalk.H270}
      leafStyles={`
        top: 17px;
        left: 13px;
        transform-origin: 61% 97%;
      `}
    />
  );
};

export default Error01;
