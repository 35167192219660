import React from 'react';

const GalleryHeaderWaves = props => (
  <svg
    {...props}
    css={`
      path {
        fill: none;
        stroke: #405036;
        stroke-miterlimit: 10;
        stroke-width: 5px;
        opacity: 0.388;
      }
    `}
    xmlns="http://www.w3.org/2000/svg"
    width="481.07"
    height="83.94"
  >
    <defs />
    <g id="FALKA" transform="translate(53.036 -743.5)" opacity="0.5">
      <path
        id="Path_225"
        d="M427.94 49.23c-51.33-1.74-54.26-18.6-111.16-18.6-60 0-60 18.74-120 18.74s-60-18.74-120-18.74c-30 0-45 4.68-60 9.37"
        data-name="Path 225"
        transform="translate(-53.04 755.5)"
      />
      <path
        id="Path_226"
        d="M427.94 63.29c-51.33-1.74-54.26-18.6-111.16-18.6-60 0-60 18.75-120 18.75s-60-18.75-120-18.75c-30 0-45 4.68-60 9.37"
        data-name="Path 226"
        transform="translate(-53.04 761.5)"
      />
      <path
        id="Path_227"
        d="M414.07 34.24c-38.56-4-46-17.68-97.29-17.68-60 0-60 18.75-120 18.75s-60-18.75-120-18.75"
        data-name="Path 227"
        transform="translate(-53.04 749.5)"
      />
      <path
        id="Path_228"
        d="M480.48 16.56c-11 2.69-24.15 4.69-43.72 4.69-60 0-60-18.75-120-18.75s-60 18.75-120 18.75-60-18.75-120-18.75C36.43 2.5 23.24 11 .6 16.56"
        data-name="Path 228"
        transform="translate(-53.04 743.5)"
      />
    </g>
  </svg>
);

export default GalleryHeaderWaves;
