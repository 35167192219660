import Left1 from './Left1';
import Left2 from './Left2';
import Right1 from './Right1';
import BottomRight1 from './BottomRight1';
import BottomRight2 from './BottomRight2';
import BottomWaves from './BottomWaves';
import ContactLeft1 from './ContactLeft1';
import Error01 from './Error01';
import Error02 from './Error02';
import Error03 from './Error03';
import Error04 from './Error04';
import Error05 from './Error05';
import Error06 from './Error06';
import Error07 from './Error07';
import Error08 from './Error08';
import Error09 from './Error09';
import Error10 from './Error10';
import Error11 from './Error11';
import Error12 from './Error12';

export default {
  Left1,
  Left2,
  Right1,
  BottomRight1,
  BottomRight2,
  BottomWaves,
  ContactLeft1,
  Error01,
  Error02,
  Error03,
  Error04,
  Error05,
  Error06,
  Error07,
  Error08,
  Error09,
  Error10,
  Error11,
  Error12,
};
