import React from 'react';

import { display, zIndex } from 'styled-system';
import { Box } from '@rebass/grid';
import Flower from '../../Flowers';
import Waves from '../../../assets/waves';

const LeftFlowers = () => {
  return (
    <Box
      css={`
        ${display}
        position: relative;
        top: 260px;
        right: 100px;
      `}
      display={{ mobile: 'none', laptop: 'block' }}
    >
      <Flower.Right1
        css={`
          ${zIndex}
          position: absolute;
          bottom: 70%;
          left: 60px;

          @media (min-width: 1332px) {
            transform: rotateY(180deg);
          }
          @media (min-width: 1600px) {
            transform: none;
          }
        `}
        zIndex={5}
      />
      <Waves.HomeGallery
        css={`
          position: absolute;
        `}
      />
    </Box>
  );
};

export default LeftFlowers;
