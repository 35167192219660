import { useState, useEffect } from 'react';

const useStartOnPosition = (componentRef, marginBottom = 20) => {
  const [shouldStart, setShouldStart] = useState(false);

  const handleWindowScroll = () => {
    const windowHeight = window.innerHeight;
    const componentBottom = componentRef && componentRef.current.getBoundingClientRect().bottom;

    if (!shouldStart && windowHeight > componentBottom + marginBottom) setShouldStart(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  });

  return shouldStart;
};

export default useStartOnPosition;
