import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@rebass/grid';
import { compose, position, zIndex } from 'styled-system';

import Header from './Header';
import Images from './Images';

const Gallery = ({ gallery }) => {
  return (
    <Box
      as="section"
      css={compose(
        zIndex,
        position,
      )}
      mt={{ mobile: 0, laptop: 60 }}
      px={[20, 36]}
      zIndex={3}
      position="relative"
    >
      <Header />
      <Images gallery={gallery} />
    </Box>
  );
};

Gallery.propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
