import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import { fontSize, space, display } from 'styled-system';

import Waves from '../../../assets/gallery-header-waves';

const Header = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      css={`
        position: relative;
      `}
      mb={{ mobile: 60, laptop: 80 }}
    >
      <Waves
        css={`
          ${display}
          position: absolute;
        `}
        display={{ tablet: 'none' }}
      />
      <h3
        css={`
          ${space}
        `}
        mb={[0, -10]}
        mx={[0, 40]}
      >
        <FormattedMessage id="home.gallerySubHeader" />
      </h3>
      <h2
        css={`
          ${fontSize}
          margin: 0;
        `}
        fontSize={{ mobile: 30, laptop: 40 }}
      >
        <FormattedMessage id="home.galleryHeader" />
      </h2>
    </Flex>
  );
};

export default Header;
